import { Link } from "react-router-dom";
// import Booking from "./Booking";
// import { useState } from "react";

function CallToAction(){
  // const [active, setActive]=useState(false)
    return <> <section className="cta">
    <h2>Ready to experience life to the fullest?</h2>
    <p>Make a booking now</p>
    <Link to='/reservations' className="cta-button">Book Now</Link>
    {/* <Link href="" onClick={()=>{setActive(!active)}} className="cta-button">Book Now</Link> */}
  </section>
  {/* <Booking isActive={active}/> */}
  </>
}

export default CallToAction;