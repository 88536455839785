import Image10 from "../img/gallery/MM.png";
import Image11 from "../img/gallery/Mig0.jpg";
import Image12 from "../img/gallery/L.jpg";
import Image20 from "../img/gallery/A1.jpeg";
import Image21 from "../img/gallery/A2.jpg";
import Image22 from "../img/gallery/A3.jpg";
import Image30 from "../img/gallery/GC.jpg";
import Image31 from "../img/gallery/Nai.jpg";
import Image32 from "../img/gallery/G1.jpg";
import Image40 from "../img/gallery/LN.jpeg";
import Image41 from "../img/gallery/LN1.jpeg";
import Image42 from "../img/gallery/LN2.jpg";
import Image50 from "../img/gallery/Ts0.jpeg";
import Image51 from "../img/gallery/Ts2.jpeg";
import Image52 from "../img/gallery/Ts1.jpeg";
import Image60 from "../img/gallery/S2.jpeg";
import Image61 from "../img/gallery/S1.jpeg";
import Image62 from "../img/gallery/S0.jpeg";
const Pictures = [
    {
      id:1,
      country:'Kenya',
      img: Image10,
      img2:Image11,
      img3:Image12,
      title: "Maasai Mara National Reserve: Where the Wild Roam Free on African Savannahs",
      text:"The Maasai Mara National Reserve is a true African wilderness gem. Famous for its breathtaking landscapes and rich biodiversity. The reserve is perhaps best known for hosting the annual wildebeest migration, where millions of animals cross the Mara River in search of fresh grazing. The Maasai people, known for their vibrant culture, inhabit the area adding a unique culture dimension to your safari experience.",
    },
    {    
      id:2,  
      country:'Kenya',
      img: Image20,
      img2:Image21,
      img3:Image22,
      title: "Amboseli National Park:Witnessing Elephants graze Beneath the Gaze of Kilimanjaro",
      text:"Nestled at the foothills of the imposing Mount Kilimanjaro, Amboseli National Park ia a picturesque haven for both nature and mountain enthusiasts. It's famous for its large herds of elephants and stunning views of Mount Kilimanjaro in neighboring Tanzania. The marshy areas of Amboseli attract an abundance of birdlife making it a birdwatcher's paradise. It's a unique destination where you can capture stunning phtographs of wildlife against the backdrop of the snow-capped Kilimanjaro."
    },
    {
      id:3,
      country:'Kenya',
      img: Image30,
      img2:Image31,
      img3:Image32,
      title: "Nairobi: Blending Urban Energy with wildlife conservation",
      text:"Kenya's capital city is a hub of culture, history and wildlife conservation. This bustling metropolis is a dynamic blend of modernity and rich cultural heritage. The city boasts attractions like the Nairobi National Park, Giraffe Centre and Karen Blixen Meseum, just to mention a few. The city's markets offer a chance to experience local flavours and crafts. It's also known for it's vibrant nightlife, restaurants serving diverse cuisines, and a thriving arts scene. It's a place one can start their Kenyan safari journey while enjoying comforts of a modern city."
    },
    {
      id:4,      
      country:'Kenya',
      img: Image40,
      img2:Image41,
      img3:Image42,
      title: "Lake Nakuru National Park: Where Flamingos Paint the Waters Pink",
      text:"Located in the Great Rift Valley of Kenya, it's a haven for bird lovers and wildlife entusiasts. It's renowed for its stunning scenery, centered around the shallow alkaline lake, which shimmers with the presence of thousands of flamingos. Beyond the flamingos, the park is teeming with wildlife, like both black and white rhinoceros, which is a testament to the conservation efforts in place. The surrounding accacia forests provide a backdrop to your safari adventures, and the viewpoints around the park offer oanoramic vistas of the lake and its inhabitants."
    },
    {
      id:5,      
      country:'Kenya',
      img: Image50,
      img2:Image51,
      img3:Image52,
      title: "Tsavo National Parks(East and West): A Tale of vast Landscapes & Magestic Wildlife",
      text:"These parks are known for their rugged, untamed beauty and vast landscapes that stretch as far as the eye can see Tsavo East, characterized by its open savanahs and the Galana River, is home to a wide range of wildlife The Yatta Plateu, one of the world's largest lava flows, adds a unique geological aspect to one's visit. Tsavo West is equally captivating, with its diverse topography including volcanic craters, caves, and Mzima Springs, ehere you can observe hippos and crocodilrs underwater through a submergeed chamber. The Chyulu Hills, a volcanic mountaing range, provide a dramatic backdrop to your safari, and the park's rock formations and ancient laa flows tell a geological story that adds depth to your experience."
    },
    {
      id:6,      
      country:'Kenya',
      img: Image60,
      img2:Image61,
      img3:Image62,
      title: "Samburu National Reserves: A journey of cultural and the Special Five discovery",
      text:"Situated in northern Kenya, Samburu National Reserve is a wilderness gem celebrated for it's unique wildlife, including the Samburu Special Five, and captivating landscapes The Ewaso Ngi'ro River runs through Samburu, creating a lush vegetation along it's banks, where elephants come to quench their thirst and a variety of bird species flourish. The semi-arid landscapes of Samburu, with its distinctive palms and acacia trees, offer a striking contrast to the more typical savannah regiosn of Kenya."
    }
  ];

  export default Pictures;