import { Link } from "react-router-dom";
import Pictures from "./DestinationImage";
function Destination() {
  
  return (
    <>
      <div className="container mt-5 pt-4">
        <div className="row">
          {Pictures.map((pic,key) => {
            return (
              <>
                <div className="col-md-4 p-3" key={key}>
                  <div
                    className="position-relative"
                    style={{
                      height: 280,
                      backgroundImage: `url(${pic.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <p
                      className="position-absolute text-white px-3 py-2"
                      style={{
                        zIndex: 10,
                        top: 20,
                        right: 20,
                        backgroundColor: "#355e3b",
                      }}
                    >
                      <small>{pic.country}</small>
                    </p>
                    <div
                      className="position-absolute p-3 d-flex flex-column justify-content-end"
                      style={{
                        background:
                          "linear-gradient(to bottom, transparent, rgba(0,0,0,1))",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <h3 className="h6">
                        <Link
                          to={`${key}`}
                          className="text-white"
                          style={{ lineHeight: "1.6" }}
                        >
                          {pic.title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Destination;
