import Image10 from "../img/gallery/Se0.jpeg";
import Image11 from "../img/gallery/Se1.jpeg";
import Image12 from "../img/gallery/Se2.jpeg";
import Image20 from "../img/gallery/Ng0.jpg";
import Image21 from "../img/gallery/Ng1.jpg";
import Image22 from "../img/gallery/Ng2.jpeg";
import Image30 from "../img/gallery/KM1.jpg";
import Image31 from "../img/gallery/KM2.jpg";
import Image32 from "../img/gallery/Km0.jpg";
import Image40 from "../img/gallery/bar0.jpg";
import Image41 from "../img/gallery/bar1.jpg";
import Image42 from "../img/gallery/bar2.jpeg";
import Image50 from "../img/gallery/TN.jpeg";
import Image51 from "../img/gallery/TN.jpg";
import Image52 from "../img/gallery/TN2.jpeg";
import Image60 from "../img/gallery/LM.jpeg";
import Image61 from "../img/gallery/LM1.jpeg";
import Image62 from "../img/gallery/LM2.jpeg";
import Image70 from "../img/gallery/SS.jpg";
import Image71 from "../img/gallery/SS1.jpeg";
import Image72 from "../img/gallery/SS2.jpeg";
const Pictures = [
    {
      id:1,
      country:'Tanzania',
      img: Image10,
      img2:Image11,
      img3:Image12,
      title: "Serengeti National Park: Witness the Great Migration",
      text:"Arguably the most iconic wildlife spectacle on the planet unfolds in the Serengeti National Park. Here, you will be privy to the annual Great Migration, where millions of wilderbeests, zebras, and other herbivores embark on a perilous journey across the plains in search of greener pastures. Predators like lions, cheetahs and hyenas follow in their wake, creating scenes of raw nature rarely witnessed elsewhere.",
    },
    {    
      id:2,  
      country:'Tanzania',
      img: Image20,
      img2:Image21,
      img3:Image22,
      title: "Ngorongoro Conservation Area: A Natural Wonder",
      text:"The Ngorongoro Conservation Area shelters the breathtaking Ngorongoro Crater, often described as the 'eighth wonder of the world'. This massive caldera is home to a self-contained ecosystem, hosting an astonishing concentration of wildlife, including the Big Five. The juxtaposition of the crater's lush floor against the towering crater walls makes it a visual masterpiece."
    },
    {
      id:3,
      country:'Tanzania',
      img: Image30,
      img2:Image31,
      img3:Image32,
      title: "Mount Kilimanjaro: Africa's Rooftop",
      text:"Mount Kilimanjaro, Africa's tallest peak, is an irresistible challenge for trekkers and mountaineers. It's snow caped summit beckons adventures from all walk of life. The climb through a variety of ecosystems, from lush rainforests to alpine deserts, provides an unforgettable experience."
    },
    {
      id:4,      
      country:'Tanzania',
      img: Image40,
      img2:Image41,
      img3:Image42,
      title: "Zanzibar: Tropical Paradise",
      text:"This picturesque island off the coast of Tanzania offers relaxation, pristine beachfront bliss, historic Stone Town, and a unique cultural experience for those seeking it Crystal-clear waters, white-sand beaches, and a rich cultural heritage make Zanzibar Island and its neighboring islets a tropical paradise. Explore the labyrinthine streets of Stone Town, indulge in exotic spices, and unwind by the Indian Ocean."
    },
    {
      id:5,      
      country:'Tanzania',
      img: Image50,
      img2:Image51,
      img3:Image52,
      title: "Tarangire National Park: Where Ginats Roam Beneath Ancient Baobabs",
      text:"The park is a hidden gem known for it's unique landscape and exceptional wildlife experience. It's most distinctive feature is the Tarangire River, which attracts a multitude of animals during the dry season. It is also famous for it's large herds of elephants, often numbering in the hundreds, as well as diverse wildlife. The stunning baobab trees that dot the landscape also creates a surreal and picturesque backdrop for safaris. Visitors can enjoy game drives, guided walking safaris, and birdwatching, making it a perfect addition to any safari itinenary"
    },
    {
      id:6,      
      country:'Tanzania',
      img: Image60,
      img2:Image61,
      img3:Image62,
      title: "Lake Manyara National Park: A Water Wonderland",
      text:"Nestled at the base of the Great Rift Valley in nothern Tanzania, Lake Manyara Park is a small but ecologically diverse gem. It offers a unique blend of wildlife, landscapes and birdlife, making it a delightful stop for nature enthusiasts. One of the park's highlights it tree-climbing lions, a rare behavior see in the region. Lake Manyara's shores are teeming with flamingos and other waterfowl, creating a vibrant spectacle. In addition visitors can spot elephants, hippos, buffalos and giraffes among others. It is ideal for a day trip or shorter safari excursion and is often include in nothern Tanzania safari itinenaries"
    },
    {
      id:7,      
      country:'Tanzania',
      img: Image70,
      img2:Image71,
      img3:Image72,
      title: "Selous Game Reserve: The Untouched Wilderness of Tanzania's Southern Gem",
      text:"Located in southern Tanzania, Selous Game Reserve is one of Africa's largest and most remote protected areas, offering an exclusive and pristine safari experience. The reserve provides ample space for diverse wildlife populations to thrive. Selous is renowed for its rugged terrain, extensive river system and abundant wildlife. Unlike many other popular Tanzanian safari destinations, Selous offers a sense of solitude & untouched wilderness. Visitors can explore the reserve through the game drives, boat safaris along the Rufiji River and guided walking safaris, all while enjoying the serene beauty of this pristine African wilderness."
    },
  ];

  export default Pictures;