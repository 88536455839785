import { Link } from "react-router-dom";
import WaImg from '../img/gallery/wapp.png'
import IgImg from '../img/gallery/insta.jpeg'

function Footer() {
  const currentDate=new Date();
  const currentYear=currentDate.getFullYear();
  return (
    <>
      <footer className="bg-light text-center text-white" style={{background:'#0f3c01'}}>
        <div className="container p-4 pb-0">
          <section className="mb-4">
            <Link
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#3b5998" }}
              to="https://www.facebook.com/profile.php?id=61550857384339&mibextid=ZbWKwL"
              role="button"
            >
              <i className="material-icons">facebook</i>
            </Link>
            <Link
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#55acee" }}
              to="mailto:info@prideluxurykenyasafaris.com"
              role="button"
            >
              <i className="material-icons" >email</i>
            </Link>
            <Link
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#dd4b39" }}
              to="tel:+254715874765"
              role="button"
            >
              <i className="material-icons">phone</i>
            </Link>
            <Link
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#ac2bac" }}
              to="https://instagram.com/prideluxurykenyasafaris?igshid=MWZjMTM2ODFkZg=="
              role="button"
            >
              {/* <i className="material-icons">camera</i> */}
              <img src={IgImg} alt="mypic" width={'30'} height={'30'}/>

            </Link>
            <Link
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "green" }}
              to="https://wa.me/254715874765"
              role="button"
            >
              {/* <i className="material-icons">chat_bubble_outline</i> */}
              <img src={WaImg} alt="mypic" width={'30'} height={'30'}/>
            </Link>
          </section>
        </div>
        <div
          className="text-center p-3"
          style={{ background:'#355e3b' }}
        >
          &copy; {currentYear} PrideLuxury Kenya Safaris
          <Link className="text-white" to="Ivy-Chelsea.github.io">.
          </Link>
        </div>
      </footer>
    </>
  );
}

export default Footer;
