import Logo from '../img/logo.PNG';
import { NavLink } from 'react-router-dom';
function NavigationBar(){
    return (<>
    <nav className="navbar navbar-expand-lg fixed-top header">
      <div className="container">
        <NavLink className="navbar-brand logo" to="/"><img src={Logo} className='rounded rounded-circle' alt="Logo"/><h1><em>PrideLuxury</em></h1> </NavLink>
        <button className="navbar-toggler hidden-lg-up text-light fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
            aria-expanded="false" aria-label="Toggle navigation">&#9776;</button>
        <div className="collapse navbar-collapse navigation" id="collapsibleNavId">
         
          <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="features" aria-current="page">Features</NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/#about" aria-current="page">About</NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="contact" aria-current="page">Contact</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="kenya" aria-current="page">Kenya</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="tanzania" aria-current="page">Tanzania</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="reservations" aria-current="page">Reservations</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="car-leasing" aria-current="page">Car Leasing</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="gallery" aria-current="page">Gallery</NavLink>
            </li>
          </ul>

        </div>
      </div>
    </nav>
    </>)
}


export default NavigationBar;