function Contact() {
  return (
    <>
      {" "}
      <section id="contact" className="contact">
        <div className="container">
          <h2 className="w-100 border-bottom">Contact Us</h2>
          <div className="contact-content row">
            <div className="contact-form mx-auto col-lg-6 col-xl-6 col-dm-12 col-sm-12 ">
              <h3>Send us a message</h3>
              <form
                action="https://formspree.io/f/xrgwlgen"
                method="POST"
              >
                <input type="hidden" name="_captcha" value="false" />
               
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                />
                <textarea
                  name="message"
                  placeholder="Your Message"
                  rows="5"
                  required
                ></textarea>
                <button type="submit">Send</button>
              </form>
            </div>
            <div className="contact-details mx-auto col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center">
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.790592971304!2d36.781777999999996!3d-1.3004969999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f109987d40207%3A0x35c35ad0b10f9d1e!2sGreenhouse%20Mall!5e0!3m2!1sen!2ske!4v1694959259276!5m2!1sen!2ske"
                width={"100%"}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
