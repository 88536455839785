import { Link } from "react-router-dom";
function About(){
    return <>
    <section id="about" className="about mt-5">
    <div className="about-content w-100">
      <h1>Your ultimate Travel Partner</h1>
      <p className="fs-5">At PrideLuxury, our commitment to sustainability runs deep,
as we believe in responsible travel that respects the places we visit and the people who call them home.
We are not just tour operators, we are storytellers, using our local insights and global networks
to craft journeys and transend the ordinary.
Our dedication to quality, safety, and customer satisfaction is unwavering.
We are here to turn your travel dreams into reality, one meticulously planned adventure at a time.
Join us and discover the world in a way you'e never imagined.
Welcome to the PrideLuxury Safaris family.</p>
      <Link to='reservations' className="cta-button">Get Started</Link>
    </div>
  </section>
    </>
}

export default About;