import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pictures from "../components/DestinationImagesKe";
function DestinationDetailKe() {
  const { id } = useParams();
  const [myDestination, setmyDestination] = useState({});
  useEffect(() => {
    setmyDestination(Pictures[id]);
    
  }, [id]);
  return (
    <>

      <div className="container pt-4 mt-5">
        <div className="row blog-header">
          <div className="col text-center">
            <h4>{myDestination.title }</h4>
          </div>
        </div>
        <div className="row blog-content" style={{minHeight:'55vh'}}>
          <div className="col-md-4">
            <img
              src={myDestination.img}
              alt="myDestination.img1"
              className="img-fluid"
            />
          </div>
          <div className="col-md-4">
            <img
              src={myDestination.img2}
              alt="myDestination.img2"
              className="img-fluid"
            />
          </div>
          <div className="col-md-4">
            <img
              src={myDestination.img3}
              alt="myDestination.img3"
              className="img-fluid"
            />
          </div>
          <div className="col">
            <p>
            {myDestination.text}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DestinationDetailKe;
