import NavigationBar from "./components/NavigationBar";
import Home from "./pages/Home";
// import Features from "./pages/Features";
import AboutUs from "./pages/AboutUs";
import ContuctUs from "./pages/Contucts";
import Destinations from "./pages/Destinations";
import DestinationDetail from "./pages/DestinationDetail"
import Reservations from "./pages/Reservations";
import CarLiasing from "./pages/CarLiasing";
import Gallery from "./components/Gallery";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import DestinationDetailKe from "./pages/DestinationDetailKe";
import DestinationsKe from "./pages/DestinationsKe";
function App() {
  return (
    <>
      <Router basename="/">
        <NavigationBar />
        <Routes>
        <Route path="/" element={<Home/>}/>
        {/* <Route path="/features" element={<Features/>}/> */}
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/contact" element={<ContuctUs/>}/>
        <Route path="/tanzania" element={<Destinations/>}/>
        <Route path="/tanzania/:id" element={<DestinationDetail/>}/>
        <Route path="/kenya" element={<DestinationsKe/>}/>
        <Route path="/kenya/:id" element={<DestinationDetailKe/>}/>
        <Route path="/reservations" element={<Reservations/>}/>
        <Route path="/car-leasing" element={<CarLiasing/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/:id" element={<Gallery/>}/>
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
