import { Link } from "react-router-dom";
function DestinationLine(){
    return <>
        <div className="col-md-12">
                <div className="card-body" id="destination">
                    {/* Atom */}
                    <div id="content">
                        <ul className="atom-destination">
                            <li className="atom-destination-content" data-destintion="Kenya">
                                <h3> <Link to='/kenya' className="text-dark text-decoration-none">3 Days Maasai Mara National Park</Link> </h3>
                                <p><Link to='/kenya'  className="text-dark text-decoration-none">One of Africa’s Best Wildlife Parks! This is the home to the most amazing and stunning destination and a must-see for every traveller.  Prepare to be amazed by the extraordinary scenery of the African plains, come face to face with the king of the jungle and learn more about Maasai Warriors and  Maasai Mara.  As a traveller, find your preferred places to stay or your dream safari in Maasai Mara and allow us to plan the best safari adventure just for you.</Link></p>
                            </li>
                            <li className="atom-destination-content" data-destintion="Tanzania">
                                <h3> <Link to='/tanzania' className="text-dark text-decoration-none">3 Days Serengeti National Park</Link> </h3>
                                <p><Link to='/tanzania' className="text-dark text-decoration-none">Serengeti National Park is a World Heritage Site teeming with wildlife: Join us on a safari and explore the endless Serengeti plains dotted with trees and kopjes from which majestic lions control their kingdom; come experience the Great Migration, an elusive leopard in a riverine forest, a hot air balloon safari and the sound of lions roaring at night. If you are planning a safari to Serengeti National Park, let us tailor for you the best adventure.</Link></p>
                            </li>
                        </ul>
                    </div>
                </div>
        </div>
    </>
}


export default DestinationLine;