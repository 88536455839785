import About from "../components/About";
import Slideshow from "../components/Slideshow";
import Contact from "../components/Contuct";
import Renting from "../components/Renting";
import CallToAction from "../components/CallToAction";
import DestinationLine from "../components/DestinationLine";
function Home() {
  return (
    <>
    <About/>
    <Slideshow/>
    <DestinationLine/>
    <CallToAction/>
    <Renting/>
    <Contact/>
    </>
  );
}

export default Home;