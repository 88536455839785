import Image1 from "../img/slideshow/L.jpg";
import Image2 from "../img/gallery/IMG-20230914-WA0006.jpg";
import Image3 from "../img/gallery/bar1.jpg";
import Image4 from "../img/gallery/Gallery3.png";

function Slideshow(){
    
    return <>
    <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
            <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true" aria-label="First slide"></li>
            <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
            <li data-bs-target="#carouselId" data-bs-slide-to="2" aria-label="Third slide"></li>
            <li data-bs-target="#carouselId" data-bs-slide-to="3" aria-label="Forth slide"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
                <img src={Image1} style={{objectPosition:'top center'}} className="w-100 d-block" alt="First slide"/>
                <div class="carousel-caption d-none d-md-block">
                    <h5 className="text" style={{textShadow:'1px 1px 1px black' , color:'white'}}>Through the lens of travel, we redefine what it means to be alive.
            It is about embracing the thrill of the unknown, the magic of spontaneity,
            and the beauty that surrounds us no matter where we are.
            Together, let's write the chapter of your life's journey.
            Discover the Hidden Treasures of Our Planet, One Destination at a Time.</h5>
                </div>
            </div>
            <div className="carousel-item">
                <img src={Image2} className="w-100 d-block" alt="Second slide"/>
                <div class="carousel-caption d-none d-md-block">
                <h5 className="text" style={{textShadow:'1px 1px 1px white'}}>As you explore the world with PrideLuxury, You will find that it is not just about the destinations,<br/>
            It's about the moments that take your breathe away.<br/>
            Pride Luxury curates experiences that inspire wonder,<br/>
            ignite passion, and creates stories you will share for a lifetime.<br/>
            Every journey begins with a step, make yours an epic one.</h5>
                </div>
            </div>
            <div className="carousel-item">
                <img src={Image3} className="w-100 d-block" alt="Third slide"/>
                <div class="carousel-caption d-none d-md-block">
                <h5 className="text" style={{textShadow:'1px 1px 1px white'}}>In the heart of the heart of the world's most breathtaking landscapes,<br/>
            where the earth meets the sky and cultures intertwine,<br/>
            we invite you to journey with us.<br/>
            Immense yourself in the richness of the world's wonders,<br/>
            for in each moment of exploration, a new chapter of your life unfolds.<br/>
            Escape the Ordinary and Embrace the Extraordinary. Your Adventure Awaits.😀</h5>
                </div>
            </div>
            <div className="carousel-item">
                <img src={Image4} style={{objectPosition:'center'}} className="w-100 d-block" alt="Third slide"/>
                <div class="carousel-caption d-none d-md-block">
                <h5 className="text">Pride Luxury's mission is to turn your travel dreams into cherished memories.<br/>
            From the tranquil beaches to the rugged mountains,<br/>
            we'll guide you through destinations where every experience is experience is a brushtroke on the canvas of your life.<br/>
            Travel with us and create memories that will warm your heart forever.<br/></h5>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
    </>
}

export default Slideshow;