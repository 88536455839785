function Reservations() {
  return (
    <>
      <div className="container mt-5 pt-5" style={{minHeight:'70vh'}}>
          <form method="post" action="https://formspree.io/f/xrgwlgen" className="">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                id="name" required
              />
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                className="form-control"
                name="email"
                id="email" required
              />
               <label htmlFor="message">Message</label>
              <textarea name="message" className="form-control" rows={10}></textarea>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <label htmlFor="phone">Phone number</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                id="phone"
                required
              />
              <label htmlFor="query">Select Query</label>
              <select className="form-select-lg form-control" name="subject">
              <option value="">Enquiring for</option>           
              <option value="Corporate Travel">Corporate Travel</option>           
              <option value="Car Rental and Leasing">Car Rental and Leasing</option>           
              <option value="General Enquiry">General Enquiry</option>           
              </select>
              <input type="submit" className="form-control"></input>
            </div>
        </div>
          </form>
      </div>
    </>
  );
}

export default Reservations;
