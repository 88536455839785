import Image1 from '../img/renting/C1.jpg'
import Image2 from '../img/renting/P0.jpg'
import Image3 from '../img/renting/C2.jpg'
import Image4 from '../img/renting/P5.jpg'
import { Link } from 'react-router-dom'
function Renting(){
    return <><section className="renting">
    <div className="lease">
        <img src={Image1} alt="car pic"/>
    </div>
    <div className="lease">
        <img src={Image2} alt="car pic 2"/>
    </div>
    <div className="lease">
      <img src={Image3} alt="people with car"/>
    </div>
    <div className="lease">
      <img src={Image4} alt="people with car 2"/>
    </div>
    <p style={{color: 'white'}}>
      At LuxuryPride, we take pride in offering you the ultimate convenience and flexibility when it comes to leasing.<br/>
      Our goal is to provide you with a seamless experience that aligns with your unique transportation needs.<br/>
      Whether you are embarkig on a short term adventure or seeaking a long term commitment,<br/>
      we are here to cater for you.<br/>
      When you choose us, you are not just renting a car, you are gaining access to a world of possibilities.<br/>
      We are also flexible regarding the duration of hire, and offer attractive discounts on long-term rentals.
    </p>
    <Link to='/reservations' className="cta-button">Book Now</Link>
  </section></>
}

export default Renting;