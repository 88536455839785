import GalleryImages from "./GalleryImages";
function Gallery() {
  return (
    <>
    <div className="container">
      <div className="row mt-5 pt-4" style={{background:''}}>
        {GalleryImages.map((img, key) => {
          return (
            <>
              <div className="col-lg-4 col-md-12 mb-1 mb-lg-0" key={`${key}`}>
                <img
                  src={img.img}
                  className="w-100 shadow-1-strong rounded mb-4"
                  alt="myalt"
                />
              </div>
            </>
          );
        })}
      </div>
      </div>
    </>
  );
}

export default Gallery;
