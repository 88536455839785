import Image1 from "../img/gallery/IMG-20230914-WA0006.jpg";
import Image2 from "../img/gallery/IMG-20230914-WA0007.jpg";
import Image3 from "../img/gallery/Gallery2.jpg";
import Image4 from "../img/gallery/C1.jpg";
import Image5 from "../img/gallery/C2.jpg";
import Image6 from "../img/gallery/G1.jpg";
import Image7 from "../img/gallery/A3.jpg";
import Image8 from "../img/gallery/GC.jpg";
import Image9 from "../img/gallery/KM1.jpg";
import Image10 from "../img/gallery/KM2.jpg";
import Image11 from "../img/gallery/Km0.jpg";
import Image12 from "../img/gallery/L.jpg";
import Image13 from "../img/gallery/LM.jpeg";
import Image14 from "../img/gallery/LN.jpeg";
import Image15 from "../img/gallery/LN1.jpeg";
import Image16 from "../img/gallery/Ts0.jpeg";
import Image17 from "../img/gallery/bar0.jpg";
import Image18 from "../img/gallery/bar2.jpeg";
import Image19 from "../img/gallery/MM.png";
import Image20 from "../img/gallery/Mig0.jpg";
import Image21 from "../img/gallery/Gallery1.jpg"
import Image22 from "../img/gallery/Gallery2.jpg"
import Image23 from "../img/gallery/Gallery3.jpg"
import Image24 from "../img/gallery/G2.jpg"

const GalleryImages = [
  {
    img: Image21,
  },
  {
    img: Image22,
  },
  {
    img: Image23,
  },
  {
    img: Image24,
  },{
    img: Image1,
  },
  {
    img: Image2,
  },
  {
    img: Image20,
  },
  {
    img: Image4,
  },
  {
    img: Image5,
  },
  {
    img: Image6,
  },
  {
    img: Image7,
  },
  {
    img: Image8,
  },
  {
    img: Image9,
  },
  {
    img: Image10,
  },
  {
    img: Image11,
  },
  {
    img: Image12,
  },
  {
    img: Image13,
  },
  {
    img: Image14,
  },
  {
    img: Image15,
  },
  {
    img: Image16,
  },
  {
    img: Image17,
  },
  {
    img: Image18,
  },
  {
    img: Image19,
  },
  {
    img: Image3,
  },
];
export default GalleryImages;
